<template>

    <div class="all" :style="{backgroundImage: 'url(' + bg + ')'}">
        <div class="zhanwei"></div>

        <div v-if="loginStatus">
            <div class="order-title">
                <div @click="OrderTypeClick(0)" class="order-public-all">
                    <div>所有订单</div>
                    <div v-show="pageType == 0" class="order-sanjiao"></div>
                </div>
		<div @click="OrderTypeClick(3)" class="order-public-all">
                    <div>待入库</div>
                    <div v-show="pageType == 3" class="order-sanjiao"></div>
                </div>
                <div @click="OrderTypeClick(1)" class="order-public-all">
                    <div>待发货</div>
                    <div v-show="pageType == 1" class="order-sanjiao"></div>
                </div>
                <div @click="OrderTypeClick(2)" class="order-public-all">
                    <div>已发货</div>
                    <div v-show="pageType == 2" class="order-sanjiao"></div>
                </div>
                
            </div>
            <div class="order-main">
                <div v-if="orderList.length <= 0" class="noData-all">
                    <el-image class="noData-image" :src="require('@/assets/image/noData.png')" ></el-image>
                </div>
                <div v-for="(item,key) in orderList" :key="key" class="order-public">
                    <div class="order-flex">
                        <el-image  class="all-image-title" :src="require('@/assets/image/hiayun1.png')"></el-image>
                        <div class="order-txt-size">{{item.wl_type == 1 ?'海运':'空运'}}</div>
                    </div>
                    <div class="order-flex order-address">
                        <div class="order-address-public">
                            <div class="address-olor">{{item.ji_sheng}}</div>
                            <div>{{item.jj_name}}</div>
                        </div>
                        <div class="order-content">
                            <el-image v-if="item.status == 1"  class="all-image-address" :src="require('@/assets/image/kongyun2.png')"></el-image>
                            <el-image v-if="item.status == 2"  class="all-image-address" :src="require('@/assets/image/hiayun2.png')"></el-image>
                            <div v-if="item.status == 0">待入库</div>
                            <div v-if="item.status == 1">待发货</div>
                            <div v-if="item.status == 2">已发货</div>
                        </div>
                        <div class="order-address-public">
                            <div class="address-olor">{{item.shou_sheng}}</div>
                            <div>{{item.sj_name}}</div>
                        </div>
                    </div>
                    <div class="order-flex card-bottom">
                        <div>提交订单时间：{{item.createtime}}</div>
                        <router-link class="link" :to="{path:'/detail',query: {id: item.id,status:item.status}}" >
                            <div class="card-button  card-button-bg-lanse">查看详情</div>
                        </router-link>   
                    </div>
                </div>
            </div>
        </div>
        <div v-else style="text-align: center;">
            <div class="istoken">
                <el-image class="istoken-image" :src="require('@/assets/image/gonggao.png')" ></el-image>
            </div>
            <!-- <el-image :src="require('@/assets/image/gonggao.png')" ></el-image> -->
        </div>

    </div>
</template>
<script>
import {order,getset} from '@/api/index'
export default {
    name:'Order',
    data(){
        return{
            // 0所有订单  1代发货  2已发货
            pageType:0,
            orderList:[],
            bg:'',
            loginStatus:false
        }
    },
    created(){
        getset({name:'order_bg'}).then(res=>{this.bg = this.$store.state.baseUrl + res.data})
        let token = JSON.parse(sessionStorage.getItem('token'))

        if(token){
            this.getOrder()
            this.loginStatus = true
        }else{
            this.$message({
                type:'error',
                message:'请先登录'
            })
            this.loginStatus = false
        }
        
    },
    methods:{
        //
        getOrder(){
            let data = {}
	    data.status = this.pageType
            if(this.pageType == 0){
                data.status = '-1' + ''
            }
	    if(this.pageType == 3){
		data.status = 0
	    }
            order(data).then(res=>{
                this.orderList = res.data
            })
        },
        // 切换标签
        OrderTypeClick(id){
            if(id == this.pageType){
                return
            }
            this.pageType = id
            this.getOrder()
            // switch(id){
            //     case 0:
            //         this.getOrder()
            //     break;
            //     case 1:
            //         this.
            //     break;
            //     case 2:
            //         console.log('1w23');
            //     break;
            // }
            
        },

    }
}
</script>
<style scoped>
.all{
    background-attachment:fixed;
    background-repeat: no-repeat;
    background-size: cover;
}
.zhanwei{
    height: 120px;
}
.order-main{
    width: 50%;
    min-height: 600px;
    margin: auto;
    margin-top: 20px;
    padding-bottom: 20px;
}
.order-title{
    height: 140px;
    background: #F6AF16;
    width: 100%;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center
}
.order-public-all{
    position: relative;
    width: 200px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.order-flex{
    display: flex;
    align-items: center;
}
.all-image-title{
    height: 25px;
    width: 25px;
}
.all-lanse{
    color: #5E89FF;    
}
.all-lanse{
    color: #D81E06;
}
.order-public{
    padding: 50px ;
    background: #FFFFFF;
    border-radius: 10px;
    font-size: 20px;
    color: #B1B1B1;
    margin-bottom: 20px;
}
.order-txt-size{
    font-size: 24px;
    margin-left: 20px;
}
.order-address{
    margin-top: 40px;
    justify-content: space-around;
}
.order-address-public{
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
}
.address-olor{
    font-size: 28px;
    font-weight: 500;
    color: #333333;
    line-height: 60px;
}
.all-image-address{
    width: 90px;
    height: 30px;
    margin-bottom: 20px;
}
.order-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
}
.card-bottom{
    margin-top: 60px;
    justify-content: space-between;
}
.card-button{
    width: 111px;
    height: 38px;
    text-align: center;
    line-height: 38px;
    border-radius: 19px;
    font-size: 20px;
    font-weight: 500;
    color: #FFFFFF;
}
.card-button-bg-lanse{
    background: #5E89FF;
}
.card-button-bg-hongse{
    background: #D81E06;
}
.link{
    color: #00B8FF;
    text-decoration: none;
}
.order-sanjiao{
    position: absolute;
    bottom: 0;
    width: 0; 
    height: 0;
    border-width: 20px;
    border-style: solid;
    border-color: transparent transparent #23367C transparent;
}
.noData-all{
    text-align: center;
}
.noData-image{
    width: 500px;
    height: 500px;
}
.istoken{
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.istoken-image{
    width: 850px;
    margin-bottom: 250px;
}
</style>